<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col>
                <v-select
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    v-model="time_frame"
                    :items="timeframeOptions"
                    :label="$store.getters.getTextMap().time_frame"
                    dense
                    outlined
                    item-text="label"
                    item-value="value"
                    ></v-select>  
            </v-col>
            <v-spacer/>
            <v-col cols="auto" class="d-flex" align="right">
                <v-btn-toggle 
                    v-model="item_type" 
                    dense
                    :color="$store.getters.getColorPalette().foregroundColorCode"
                    :background-color="$store.getters.getColorPalette().backgroundColorCode"
                    :dark="$store.getters.getColorPalette().isDark"
                    >
                    <v-btn>
                        <v-icon>mdi-text-short</v-icon>{{$store.getters.getTextMap().esg_summary_item}}
                    </v-btn>
                    <v-btn>
                        <v-icon>mdi-text-long</v-icon>{{$store.getters.getTextMap().esg_details_item}}
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
        <v-row v-if="item_type==0" class="mt-2">
            <v-col>
                <v-row no-gutters>
                    <v-col>
                        <v-card :style="{'border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" >
                            <ESGLoggedDataGraphByScopeTemp :timeframe="time_frame"/>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card :style="{'border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" >
                            <ESGInsightsByScopeTemp :timeframe="time_frame"/>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card :style="{'border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" >
                            <ESGScopeTableByNameTemp :timeframe="time_frame"/>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <ESGScopeWiseDetailsTemp :timeframe="time_frame" v-else/>
    </v-container>
</template>
<script>
import ESGLoggedDataGraphByScopeTemp from '@/components/items/esg/ESGLoggedDataGraphByScopeTemp'
import ESGInsightsByScopeTemp from '@/components/items/esg/ESGInsightsByScopeTemp'
import ESGScopeTableByNameTemp from '@/components/items/esg/ESGScopeTableByNameTemp'
import ESGScopeWiseDetailsTemp from '../details/esg/ESGScopeWiseDetailsTemp.vue'
export default {
    name:'ESGPanelTemp',
    components:{
        ESGLoggedDataGraphByScopeTemp,
        ESGInsightsByScopeTemp,
        ESGScopeTableByNameTemp,
        ESGScopeWiseDetailsTemp
    },
    data(){
        return {
            time_frame:365,
            item_type:0,
            timeframeOptions:[
                {label:this.$store.getters.getTextMap().last_30_days, value:30},
                {label:this.$store.getters.getTextMap().last_60_days, value:60},
                {label:this.$store.getters.getTextMap().last_90_days, value:90},
                {label:this.$store.getters.getTextMap().last_180_days, value:180},
                {label:this.$store.getters.getTextMap().last_365_days, value:365},
                {label:this.$store.getters.getTextMap().yesterday, value:1},
                {label:this.$store.getters.getTextMap().mtd, value:'month'},
                {label:this.$store.getters.getTextMap().ytd, value:'year'}
            ],
        }
    },
    watch:{
        item_type(){
            if(!this.item_type){
                this.item_type=0
            }
        }
    }
}
</script>