<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <v-select
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    v-model="group_by_key"
                    :items="groupByOptions"
                    :label="$store.getters.getTextMap().group_by"
                    dense
                    outlined
                    item-text="label"
                    item-value="value"
                    ></v-select>  
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col >
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
                    <v-row>
                        <v-col>
                            <div>
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                <template v-slot:default>
                                        <thead>
                                            <tr >
                                                <th colspan="3" :style="'color:'+$store.getters.getColorPalette().accentCode" class="text-center"><strong> {{ $store.getters.getTextMap().scope_1 }}</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="item.machine_id" v-for="item in scope1_data">
                                                <td><strong>{{ item.name.toUpperCase() }}</strong></td>
                                                <td><strong>{{  item.tco2.toFixed(2)}}  {{ $store.getters.getTextMap().kgco2 }}</strong></td>
                                            </tr>
                                        </tbody>
                                    </template> 
                                </v-simple-table>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col class="mx-2">
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
                    <v-row>
                        <v-col>
                            <div>
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                <template v-slot:default>
                                        <thead>
                                            <tr >
                                                <th colspan="3" :style="'color:'+$store.getters.getColorPalette().accentCode" class="text-center"><strong>{{ $store.getters.getTextMap().scope_2 }}</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="item.machine_id" v-for="item in scope2_data">
                                                <td><strong>{{ item.name.toUpperCase() }}</strong></td>
                                                <td><strong>{{  item.tco2.toFixed(2)}}  {{ $store.getters.getTextMap().kgco2 }}</strong></td>
                                            </tr>
                                        </tbody>
                                    </template> 
                                </v-simple-table>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col >
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
                    <v-row>
                        <v-col>
                            <div>
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                <template v-slot:default>
                                        <thead>
                                            <tr >
                                                <th colspan="3" :style="'color:'+$store.getters.getColorPalette().accentCode" class="text-center"><strong>{{ $store.getters.getTextMap().scope_3 }}</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="item.machine_id" v-for="item in scope3_data">
                                                <td><strong>{{ item.name.toUpperCase() }}</strong></td>
                                                <td><strong>{{  item.tco2.toFixed(2)}}  {{ $store.getters.getTextMap().kgco2 }}</strong></td>
                                            </tr>
                                        </tbody>
                                    </template> 
                                </v-simple-table>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import moment from 'moment'
import axios from 'axios'
export default {
    name:'ESGScopeTableByNameTemp',
    props:['timeframe'],
    components:{
        InfoAlert,
    },
    data(){
        return{
            showDismissibleAlert:false,
            loading:false,
            info:'',
            group_by_key:'source',
            groupByOptions:[
                {label:this.$store.getters.getTextMap().source,value:"source"},
                {label:this.$store.getters.getTextMap().name,value:"name"},
                {label:this.$store.getters.getTextMap().type,value:"type"},
                {label:this.$store.getters.getTextMap().sku,value:"sku_id"},
            ],
            scope1_data:[],
            scope2_data:[],
            scope3_data:[],
            dbData:[],  
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            let from_date=null
            if(this.timeframe =='month' || this.timeframe =='year'){
                switch(this.timeframe){
                    case 'month':
                        from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
                        break;
                    case 'year':
                        from_date=moment().subtract(1, 'days').startOf('year').format('YYYYMMDD')  
                        break;
                }
            }else{
                from_date=moment().subtract(this.timeframe,'days').format('YYYYMMDD')
            }
            axios.post(this.$store.state.api+'getTotalESGScopeDataBetweenDateTemp',{from_date,group_by_keys:[this.group_by_key]},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    this.dbData=response.data.data
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                    this.loading=false
                }
            }).catch(err=>{
                console.error(err)
                this.info=err
                this.showDismissibleAlert=true
                this.loading=false
            })
        },
        createScopeDataByName(){
            this.scope1_data=[]
            this.scope2_data=[]
            this.scope3_data=[]
            for(let i of this.dbData){
                switch( i.scope){
                    case '1':
                        this.scope1_data.push({'name':i[this.group_by_key],tco2:i.total_tco2})
                        break;
                    case '2':
                        this.scope2_data.push({'name':i[this.group_by_key],tco2:i.total_tco2})
                        break;
                    case '3':
                        this.scope3_data.push({'name':i[this.group_by_key],tco2:i.total_tco2})
                        break;
                }
            }
        },
    },
    watch:{
        dbData: function (){
            this.createScopeDataByName()
        },
        timeframe: function (){
            this.getData()
        },
        group_by_key: function (){
            this.getData()
        }
    }
}
</script>