<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters>
            <v-col  align="right">
                <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory rounded>
                    <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn>
                    <v-btn  small>{{$store.getters.getTextMap().line}}</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <div ref="vis"></div>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import Plotly from 'plotly.js'
import moment from 'moment'
import axios from 'axios'
export default {
    name:'ESGLoggedDataGraphByScopeTemp',
    props:['timeframe'],
    components:{
        InfoAlert,
    },
    data(){
        return{
            showDismissibleAlert:false,
            loading:false,
            info:'',
            graphType:'bar',
            graphData:{},
            dbData:[],
            layout : {
                colorway:this.$store.getters.getColorPalette().gradientColorCodes,
                font:{
                        color:this.$store.getters.getColorPalette().accentCode,
                    },
                plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                showlegend:true,
                autosize: true,
                xaxis: {
                    tickangle: -12,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },       
                yaxis: {
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                    title: {
                        text: this.$store.getters.getTextMap().kgco2,
                        font: {
                            size: 18,
                            color: this.$store.getters.getColorPalette().accentCode,
                        }
                    },
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            }
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            let from_date=null
            if(this.timeframe =='month' || this.timeframe =='year'){
                switch(this.timeframe){
                    case 'month':
                        from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
                        break;
                    case 'year':
                        from_date=moment().subtract(1, 'days').startOf('year').format('YYYYMMDD')  
                        break;
                }
            }else{
                from_date=moment().subtract(this.timeframe,'days').format('YYYYMMDD')
            }
            axios.post(this.$store.state.api+'getESGScopeDataBetweenDateTemp',{from_date},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    let temp=response.data.data
                    temp.sort((a, b) => new Date(a.date) - new Date(b.date));
                    this.dbData=temp
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                    this.loading=false
                }
            }).catch(err=>{
                console.error(err)
                this.info=err
                this.showDismissibleAlert=true
                this.loading=false
            })
        },
        createChart(){
            this.graphData={}
            for(let i of this.dbData){
                if(!(i.scope in this.graphData)){
                    let type=this.graphType==1?'line':'bar'
                    this.graphData[i.scope]={
                    x: [],
                    y: [],
                    name:"Scope "+i.scope,
                    type:type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
                if(this.graphData[i.scope]['x'].includes(moment(i.date).format('YYYY-MM-DD'))){
                    let index = this.graphData[i.scope]['x'].indexOf(moment(i.date).format('YYYY-MM-DD'));
                    this.graphData[i.scope]['y'][index]=this.graphData[i.scope]['y'][index]+i.tco2
                }else{
                    this.graphData[i.scope]['x'].push(moment(i.date).format('YYYY-MM-DD'))
                    this.graphData[i.scope]['y'].push(i.tco2)
                }
            }
            Plotly.newPlot(this.$refs.vis, Object.values(this.graphData), this.layout, {displaylogo: false});
        },
    },
    watch:{
        dbData: function (){
            this.createChart()
        },
        graphType: function (){
            this.createChart()
        },
        timeframe: function (){
            this.getData()
        }
    }
}
</script>