<template>
    <v-container fluid >
        <ESGPanelTemp/>
    </v-container>
</template>
<script>
import ESGPanelTemp from '@/components/panels/ESGPanelTemp'
export default {
    name:'ESGTemp',
    components:{
        ESGPanelTemp,
  },
}
</script>